import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import ReactGA from "react-ga4"
import amplitude from "amplitude-js"

import createStore from "./data/redux/store"
import * as serviceWorker from "./serviceWorker"
import { ga4MeasurementId, amplitudeTrackingId } from "./data/config/urls"
import { chatbot_setting } from "./data/config/brandSetup"

import "./data/styles/index.scss"

import AppContainer from "./modules"

export const store = createStore()

var x = document.createElement("DIV")
x.setAttribute("id", "ori-chatbot-root")
document.body.appendChild(x)

ReactDOM.render(
  <Provider store={store}>
    <AppContainer />
  </Provider>,
  document.getElementById("ori-chatbot-root")
)

export const BOT_LOAD_START_TIME = new Date().getTime()

if (chatbot_setting.amplitude_tracking) {
  amplitude.getInstance("session_tracker").init(amplitudeTrackingId, null, {
    saveEvents: true,
    includeUtm: true,
    includeReferrer: true,
  })
}

if (chatbot_setting.ga4) {
  ReactGA.initialize(ga4MeasurementId)
  ReactGA.send({
    hitType: "event",
    eventCategory: "InitialBotLoad",
    eventAction: "Initial_Bot_Load_Start",
    eventLabel: "Initial_Bot_Load_Start",
    eventValue: BOT_LOAD_START_TIME,
  })
}

serviceWorker.unregister()
