import {translator} from "../../config/brandSetup"

const states = {
  page_details: {
    device_data: {},
    banner_url: "",
    banner_key: "",
    banner_width: null,
    banner_height: null,
    direction: "ltr",
    language: translator.getLanguage()
  }
};

export default states;