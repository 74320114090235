export const socketUrl = "https://vodafone-dev.oriserve.in/liveConversations"
export const networkCheckUrl = "https://google.com/"
export const botTriggeredTrackingUrl = "https://vodafone-dev.oriserve.in/botTriggerLogging"
export const brandName = "vodafone"
export const botName = "vodafone"
export const role = "user"
export const version = 1.1
export const senderId = "dish_chat_client"
export const ga4MeasurementId = "ENTER_GA4_MEASUREMENT_ID"
export const amplitudeTrackingId = "ENTER_AMPLITUDE_KEY"
export const sentryDSN = ""
export const downloadChatlogUrl = "https://flynas-dev.oriserve.com/downloadChatHistory"
export const publicIpAddress = "https://api64.ipify.org?format=json"
export const maintainanceUrl = ""//"https://brand-base-url.com/scheduleMaintainance"
