import findLastIndex from "lodash/findLastIndex"

import actionTypes from "../actiontypes"
import states from "./states"
import { chatbot_setting } from "../../config/brandSetup"
import { setDataInLocalStorage, isEmptyObject } from "../../config/utils"
import {
  MESSAGE_READ_STATUS,
  MESSAGE_SENDER,
  LOCAL_STORAGE,
  CHATBOT_TYPE,
} from "../../config/constants"

const chat_details = (state = states.chat_details, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CHATS_STATE: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case actionTypes.UPDATE_END_CHAT: {
      const end_chat = {
        ...state.end_chat,
        ...action.payload,
      }
      setDataInLocalStorage(LOCAL_STORAGE.END_CHAT + state.psid, end_chat)
      return {
        ...state,
        end_chat,
      }
    }

    case actionTypes.HANDLE_CHATBOT_INTERFACE: {
      setDataInLocalStorage(
        LOCAL_STORAGE.IS_CHAT_OPEN + state.psid,
        action.payload
      )
      if (action.payload) {
        localStorage.removeItem(LOCAL_STORAGE.UNSEEN_MESSAGES + state.psid)
        localStorage.removeItem(LOCAL_STORAGE.NOTIFICATION_COUNT + state.psid)
      }
      return {
        ...state,
        is_chat_open: action.payload,
        notification_count: action.payload ? 0 : state.notification_count,
        unseen_messages: action.payload ? [] : [...state.unseen_messages],
      }
    }

    case actionTypes.PUSH_SENDER_MESSAGE: {
      setDataInLocalStorage(LOCAL_STORAGE.MESSAGES + state.psid, [
        ...state.messages,
        action.payload.message,
      ])
      let unseen_messages = [...state.unseen_messages]
      let notification_count = state.notification_count
      let disable_msg_after_reply = { ...state.disable_msg_after_reply }
      if (
        !state.is_chat_open &&
        chatbot_setting.chatbot_type === CHATBOT_TYPE.DEFAULT
      ) {
        unseen_messages = [...state.unseen_messages, action.payload.message]
        notification_count = 0
        setDataInLocalStorage(
          LOCAL_STORAGE.UNSEEN_MESSAGES + state.psid,
          unseen_messages
        )
        setDataInLocalStorage(
          LOCAL_STORAGE.NOTIFICATION_COUNT + state.psid,
          notification_count
        )
      }
      if (!isEmptyObject(state.disable_msg_after_reply)) {
        Object.keys(disable_msg_after_reply).forEach((key) => {
          disable_msg_after_reply[key] = true
        })
        setDataInLocalStorage(
          LOCAL_STORAGE.DISABLE_MESSAGE_AFTER_USER_REPLY + state.psid,
          disable_msg_after_reply
        )
      }

      return {
        ...state,
        unseen_messages,
        notification_count,
        disable_msg_after_reply,
        messages: [...state.messages, action.payload.message],
        skipLS: false,
        send_variable_to_apiai: false,
        sendVariableToLS: false,
        variable_name: "",
      }
    }

    case actionTypes.PUSH_RESPONSE_MESSAGE: {
      let messages = [...state.messages]
      if (action.payload.generative_identifier) {
        let index = -1
        index = findLastIndex(
          messages,
          (message) =>
            message[action.key] &&
            message[action.key] === action.payload[action.key]
        )
        const newMessage = {
          generative_identifier: action.payload.generative_identifier,
          ...action.payload.message,
        }
        if (index !== -1) {
          messages = [
            ...messages.slice(0, index),
            newMessage,
            ...messages.slice(index + 1),
          ]
        } else {
          messages = [...messages, newMessage]
        }
        setDataInLocalStorage(LOCAL_STORAGE.MESSAGES + state.psid, messages)
      } else {
        let index = -1
        if (
          chatbot_setting.chat_history_messages_retain &&
          action.payload.message.length === 1
        ) {
          index = messages.findIndex((msg) => {
            return (
              msg.chatlogId &&
              msg.chatlogId === action.payload.message[0].chatlogId
            )
          })
        } else {
          index = messages.findIndex((msg) => {
            return (
              msg.chatlogId &&
              msg.chatlogId === action.payload.message.chatlogId
            )
          })
        }
        if (index !== -1) {
          const updatedMessage = !chatbot_setting.chat_history_messages_retain
            ? action.payload.message
            : action.payload.message[0]
          messages = [
            ...messages.slice(0, index),
            updatedMessage,
            ...messages.slice(index + 1),
          ]
        } else {
          if (
            chatbot_setting.chat_history_messages_retain &&
            action.payload.message.length > 0
          ) {
            messages = [...state.messages, ...action.payload.message]
          } else {
            messages = [...state.messages, action.payload.message]
          }
        }

        setDataInLocalStorage(LOCAL_STORAGE.MESSAGES + state.psid, messages)
      }
      let notification_count = state.notification_count
      let unseen_messages = [...state.unseen_messages]
      let disable_msg_after_reply = { ...state.disable_msg_after_reply }

      if (
        chatbot_setting.chat_history_messages_retain &&
        action.payload.message.length > 0
      ) {
        action.payload.message.forEach((message) => {
          if (message.disableAfterUserReply) {
            disable_msg_after_reply[message.chatlogId] = false
          }
        })
      } else if (action.payload.message.disableAfterUserReply) {
        disable_msg_after_reply[action.payload.message.chatlogId] = false
      }

      setDataInLocalStorage(
        LOCAL_STORAGE.DISABLE_MESSAGE_AFTER_USER_REPLY + state.psid,
        disable_msg_after_reply
      )

      if (
        !chatbot_setting.hide_notification_msg_after_close_bot &&
        !state.is_chat_open &&
        chatbot_setting.chatbot_type === CHATBOT_TYPE.DEFAULT
      ) {
        notification_count++
        if (
          chatbot_setting.chat_history_messages_retain &&
          action.payload.message.length > 0
        )
          unseen_messages = [
            ...state.unseen_messages,
            ...action.payload.message,
          ]
        else
          unseen_messages = [...state.unseen_messages, action.payload.message]
        setDataInLocalStorage(
          LOCAL_STORAGE.UNSEEN_MESSAGES + state.psid,
          unseen_messages
        )
        setDataInLocalStorage(
          LOCAL_STORAGE.NOTIFICATION_COUNT + state.psid,
          notification_count
        )
      }

      if (
        chatbot_setting.chat_history_messages_retain &&
        action.payload.message.length > 0
      ) {
        return {
          ...state,
          notification_count,
          unseen_messages,
          disable_msg_after_reply,
          messages,
          skipLS:
            action.payload.message[action.payload.message.length - 1]?.skipLS,
          sendVariableToLS:
            action.payload.message[action.payload.message.length - 1]
              ?.sendVariableToLS,
          send_variable_to_apiai:
            action.payload.message[action.payload.message.length - 1]
              ?.send_variable_to_apiai,
          variable_name:
            action.payload.message[action.payload.message.length - 1]
              ?.variable_name,
        }
      } else {
        return {
          ...state,
          notification_count,
          unseen_messages,
          disable_msg_after_reply,
          messages,
          skipLS: action.payload.message.skipLS,
          sendVariableToLS: action.payload.message.sendVariableToLS,
          send_variable_to_apiai: action.payload.message.send_variable_to_apiai,
          variable_name: action.payload.message.variable_name,
        }
      }
    }

    case actionTypes.UPDATE_TYPING_INFO: {
      return {
        ...state,
        is_typing: action.payload.isTyping,
        typing_text: action.payload.typingMessage
          ? action.payload.typingMessage
          : "",
      }
    }

    case actionTypes.UPDATE_MESSAGE_PAYLOAD: {
      let messages = [...state.messages]
      let index = -1
      index = findLastIndex(
        messages,
        (message) =>
          message[action.key] &&
          message[action.key] === action.payload[action.key]
      )
      if (index !== -1) {
        messages = [
          ...messages.slice(0, index),
          {
            ...messages[index],
            payload: Array.isArray(messages[index].payload)
              ? messages[index].payload.map((item) => ({
                  ...item,
                  ...action.payload.changedValue,
                }))
              : {
                  ...messages[index].payload,
                  ...action.payload.changedValue,
                },
            ...action.payload.changedMessageKey,
          },
          ...messages.slice(index + 1),
        ]
        setDataInLocalStorage(LOCAL_STORAGE.MESSAGES + state.psid, messages)
      }
      return {
        ...state,
        messages,
      }
    }

    case actionTypes.PUSH_STREAMED_MESSAGE: {
      let messages = [...state.messages]
      let index = -1
      index = findLastIndex(
        messages,
        (message) =>
          message[action.key] &&
          message[action.key] === action.payload[action.key]
      )
      if (index !== -1) {
        messages = [
          ...messages.slice(0, index),
          {
            ...messages[index],
            ...action.payload.changedValue,
          },
          ...messages.slice(index + 1),
        ]
      } else {
        messages = [
          ...messages,
          {
            ...action.payload.changedValue,
            generative_identifier: action.payload.generative_identifier,
          },
        ]
      }
      setDataInLocalStorage(LOCAL_STORAGE.MESSAGES + state.psid, messages)
      return { ...state, messages }
    }

    case actionTypes.UPDATE_MESSAGE: {
      let messages = [...state.messages]
      let index = -1
      index = findLastIndex(
        messages,
        (message) =>
          message[action.key] &&
          message[action.key] === action.payload[action.key]
      )
      if (index !== -1) {
        messages = [
          ...messages.slice(0, index),
          {
            ...messages[index],
            ...action.payload.changedValue,
          },
          ...messages.slice(index + 1),
        ]
        setDataInLocalStorage(LOCAL_STORAGE.MESSAGES + state.psid, messages)
        return {
          ...state,
          messages,
        }
      }
      return state
    }

    case actionTypes.MESSAGE_SEEN: {
      let messages = [...state.messages]
      for (let i = 0; i < messages.length; i++) {
        if (
          messages[i].readStatus === MESSAGE_READ_STATUS.DELIVERED &&
          messages[i].sender === MESSAGE_SENDER.CUSTOMER
        ) {
          messages[i] = {
            ...messages[i],
            readStatus: MESSAGE_READ_STATUS.SEEN,
          }
        }
      }
      return {
        ...state,
        messages,
      }
    }

    default:
      return state
  }
}

export default chat_details
