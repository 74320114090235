// import React from "react"

import {
  isAndroid,
  uniqueId,
  getDataFromLocalStorage,
  setDataInLocalStorage,
  clearAllDataFromLocalStorage,
  getPlatform,
  getQueryParamsValue,
  isEmptyObject,
} from "./utils"
import {
  TYPES,
  CHATBOT_TYPE,
  LANGUAGES,
  LOCAL_STORAGE,
  // CHAT_STATE
} from "./constants"
import * as defaultMessages from "./defaultMessages"
import * as chatbotText from "./chatbotText"
import * as assets from "../assets"

import { store } from "../../index"
// import { senderId } from "./urls"
// import actionTypes from "../../data/redux/actiontypes"
// import chatbotStyle from "./chatbotStyle"

// import MdChatBubbleOutline from "react-icons/lib/md/chat-bubble-outline"
// import FaWhatsapp from "react-icons/lib/fa/whatsapp"
// import MdOutlineMailOutline from "react-icons/lib/md/mail-outline"

//=================== INITIAL BRAND SPECIFIC LOGIC ====================

//=================== INITIAL BRAND SPECIFIC END HERE ===================

export const translator = {
  enable: false,
  query_param_key: "lang",
  text: {
    [LANGUAGES.ENGLISH]: chatbotText.english,
    [LANGUAGES.ARABIC]: chatbotText.arabic,
  },
  assets: {
    [LANGUAGES.ENGLISH]: assets.english,
    [LANGUAGES.ARABIC]: assets.arabic,
  },
  getLanguage() {
    let lang = LANGUAGES.ENGLISH
    if (this.enable) {
      //=========== BRAND SPECIFIC LOGIC ==========
      const query_params = new URLSearchParams(window.location.search)
      if (query_params.has(this.query_param_key)) {
        const key = query_params.get(this.query_param_key)
        if (this.text[key]) lang = key
      }
      //=================== END ===================
    }
    return lang
  },
}

export const chatbot_setting = {
  chatbot_type: CHATBOT_TYPE.DEFAULT, // default, fullScreen, adster,
  trigger_type: TYPES.LOTTIE, // default, lottie. floatingMenu
  floating_menu_type: TYPES.DEFAULT, //default, circular
  trigger_type_in_floating_menu: TYPES.DEFAULT, // default, lottie
  floating_menu_options: [
    // {
    //   icon: <MdChatBubbleOutline size={30} />,
    //   title: "Chat with us",
    //   type: "chatbot",
    // },
    // {
    //   icon: <FaWhatsapp size={30} />,
    //   title: "Whatsapp Us",
    //   action: () => {
    //     console.log("Whatsapp Us")
    //   },
    // },
    // {
    //   icon: <MdOutlineMailOutline size={30} />,
    //   title: "Email Us",
    //   action: () => {
    //     console.log("Email Us")
    //   },
    // },
  ],
  gtm: {
    enable: false,
    open_bot: "orichatopen",
    minimize_bot: "orichatminimize",
    close_bot: "orichatclose",
    end_chat: "orichatend",
  },
  ga4: false, // to enable Google ANalytics 4. please ensure your ga4MeasurementId before enabling it
  amplitude_tracking: false, // must set amplitudeTrackingId in url before enabling this
  track_bot_triggered: true, // to track number of user open our chatbot
  show_trigger_close: true,
  image_type_brand_name: {
    enable: false,
    redirect: false,
    link: "https://oriserve.com/",
  },
  security: {
    enable: false,
    code: "123456",
  },
  message_footer: {
    enable: true,
    voting: false,
    read_status: true,
    timestamp: true,
    showDay: false,
    generative_source: false,
  },
  emit_unread_msg_seen: true,
  auto_close_feedback_form: 2 * 1000 * 60, //in milisecond only
  feedback_form_rating_type: TYPES.IMAGE, // default or star or image
  menu_feedback_rating_type: TYPES.STAR, // star or image
  feedback_rating_reverse: false, // when feedback rating is STAR, this property does not take effect
  show_feedback_rating_linebar: false,
  feedback_rating_pivot: 3,
  rating_color_constant: false,
  hide_buttons_in_msg_bubble: false, // to hide the buttons inside the message bubbles
  default_btn_display_count: 15,
  auto_close_chatbot_on_refresh: false, //in milliseconds only
  automate_connection_time: 3600, //in seceond only
  automate_reset_chat_time: 24 * 3600 * 2, //in second only
  auto_open_chatbot: {
    enable: getQueryParamsValue("chatbot-dashboard-workflow-id", null)
      ? true
      : true,
    query_param_key: "chatbotopen",
  },
  clientAction: {
   botOpen: false,
   triggerIcon: false,
  },
  notification_sound_allowed: false,
  send_brand_data_on_user_first_msg: false,
  default_initial_delay_for_default_msg: 1000,
  send_brand_data_on_user_action: false,
  auto_emit_message: {
    enable: true,
    emit_once: false,
    get_default_messages: true,
    send_brand_data: true,
    update_last_emit: false,
    initial_delay_for_default_msg: 5000,
  },
  timestamp_tag: true,
  speech_recognition: true,
  minimize_bot: true,
  header_end_chat: true,
  header_clear_chat: false,
  close_icon_in_clear_chat: false,
  header_avatar_shape: "circle", // circle || square
  header_avatar_size: "large",
  header_avatar_on_click: {
    enable: false,
    link: "https://oriserve.com/",
  },
  header_tag_allowed_height: 420,
  powered_by: {
    visibility: false, // to enable powered by tag
    target_url: "http://oriserve.com/",
  },
  menu: {
    visible: true,
    children: {
      privacy_policy: false,
      terms_and_conditions: false,
      feedback: true,
      clear_chat: false,
      end_chat: false,
      download_chatlog: false,
      email_chatlog: false,
      file_upload: false,
    },
  },
  fileUploadInfo: {
    accept: [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/plain",
    ],
    notAllowedFileTypes: [],
    size: 2 * 1024 * 1024, // 2 MB
  },
  downloadChatlogsDuration: 24, // in hour
  downloadChatlogsFileType: [
    { label: "PDF", value: "pdf" },
    { label: "Text", value: "txt" },
  ],
  upload_accept: ["image/*"],
  upload_file: true,
  max_upload_file_size: 5 * 1024 * 1024, // 5 MB
  new_window_positon_and_size: {
    height: window.innerHeight / 2,
    width: window.innerWidth / 2,
    left: window.innerWidth / 8,
    top: window.innerHeight / 8,
  },
  chat_interface: {
    show_sender_avatar: false,
    show_receiver_avatar: true,
    avatar_shape: "circle", // circle || square
    avatar_size: "default",
    show_feedback_avatar: true,
    show_feedback_emoji: false,
    quick_reply_bg_transparent: true,
    scroll_upto_first_response_only: false,
    carousel_msg_display_type: TYPES.DEFAULT, // default, fixed, actual
    text_with_media_img_popup_disable: false,
    carousel_img_popup_disable: false,
    type_writer: {
      enable: false,
      deleting_speed: 150,
      typing_speed: 30,
    },
  },
  notification_bot: {
    visibility: true, // to enable notification popup (set value false in android and ios )
    stack_view: true, // to show to stack view of notification bot
    bottom_height_lg: 110,
    bottom_height_sm: 100,
  },
  auto_hide_notification_bubbles: {
    enable: false,
    delay: 10000, // in ms
  },
  hide_notification_msg_after_close_bot: false, // if you want to hide notication message by response event, so set true 
  auto_suggestions: true,
  ip_address_enable: true,
  retain_chat_history_on_end_chat: false,
  default_input_lock: false,
  message_reply: false,
  stream_response: false, // to enable chat streaming brand-specifically
  chat_history_messages_retain: true, // disable key - scroll_upto_first_response_only also
  remove_generative_section_key: "",
  maintanance_banner: {
    enable: false,
    pollingTime: 30 * 60 * 1000,
    downTimeMessage: {
      status: "error",
      message:
        "Sorry for the inconvenience! Our service is currently undergoing maintenance to ensure a smoother experience for you. We'll be back online shortly. Thank you for your patience!",
      input_lock: true,
    },
  },
  show_send_button: false,
  show_disclaimer: false,
  show_scroll_down_button: false,
  enable_app_sdk: true, // this key is used when client do not use sdk for app, we change the logic on this basis (by default true).
  enable_locationchange: false, // this key is used when client wants to trigger event when url parameter changes (by default false).
}

export const chatbot_default_messages = {
  enable: false,
  query_param_key: "msg",
  delay: 1000, // in ms
  getDefaultMessages() {
    if (chatbot_setting.auto_emit_message.get_default_messages) return []
    let defaultMsgs = defaultMessages.getDefaultMessagesByKey()
    if (isAndroid())
      defaultMsgs = defaultMessages.getDefaultMessagesByKey("android")
    if (this.enable) {
      //=========== BRAND SPECIFIC LOGIC ==========
      const query_params = new URLSearchParams(window.location.search)
      if (query_params.has(this.query_param_key)) {
        const key = query_params.get(this.query_param_key)
        const msgs = defaultMessages.getDefaultMessagesByKey(key)
        defaultMsgs = msgs
      }
      //=================== END ===================
    }
    return defaultMsgs
  },
}

export const chatbot_psids = {
  default_primary_value: "p",
  default_secondary_value: "s",
  primary_key_enable: false,
  primary_key: "pKey",
  secondary_key_enable: true,
  secondary_key: "chat",
  psid_ttl: 2 * 24 * 3600 * 1000, // psid time to live = 1 days
  brandLogicToGetPrimaryValue() {
    let primary_value = this.default_primary_value
    if (this.primary_key_enable) {
      //=========== BRAND SPECIFIC LOGIC TO FINDOUT PSID VALUE==========
      const query_params = new URLSearchParams(window.location.search)
      if (query_params.has(this.primary_key)) {
        primary_value = query_params.get(this.primary_key)
      }
      //=================== END ===================
    }
    return primary_value
  },
  brandLogicToGetSecondaryValue() {
    let secondary_value = this.default_secondary_value
    if (this.secondary_key_enable) {
      //=========== BRAND SPECIFIC LOGIC TO FINDOUT SECONDARY KEY VALUE==========
      const query_params = new URLSearchParams(window.location.search)
      if (query_params.has(this.secondary_key)) {
        // secondary_value = query_params.get(this.secondary_key) //* uncomment in prodution

        // ! warning:- below code should not go in PRODUCTION, so uncomment above line and comment below logic
        const sKey = query_params.get(this.secondary_key)
        if ((sKey >= 1 && sKey <= 6) || (sKey >= 20 && sKey <= 25))
          secondary_value = sKey
        // ! comment upTo here
      }
      //=================== END ===================
    }
    return secondary_value
  },
  getPsid() {
    const psidMap = getDataFromLocalStorage(LOCAL_STORAGE.PSID_MAP, {})
    let isSomethingExpired = false
    Object.keys(psidMap).forEach((sKey) => {
      if (
        psidMap[sKey].psid &&
        psidMap[sKey].expiry &&
        new Date().getTime() > psidMap[sKey].expiry
      ) {
        clearAllDataFromLocalStorage(psidMap[sKey].psid)
        psidMap[sKey].expiry = new Date().getTime() + this.psid_ttl
        if (!isSomethingExpired) isSomethingExpired = true
      }
    })
    if (isSomethingExpired)
      setDataInLocalStorage(LOCAL_STORAGE.PSID_MAP, psidMap)
    const key = `${this.brandLogicToGetPrimaryValue()}_${this.brandLogicToGetSecondaryValue()}`
    if (psidMap[key] && psidMap[key].psid) return psidMap[key].psid
    psidMap[key] = {
      psid: uniqueId(),
      expiry: new Date().getTime() + this.psid_ttl,
    }
    setDataInLocalStorage(LOCAL_STORAGE.PSID_MAP, psidMap)
    return psidMap[key].psid
  },
  setPsid(psid) {
    const psidMap = getDataFromLocalStorage(LOCAL_STORAGE.PSID_MAP, {})
    const key = `${this.brandLogicToGetPrimaryValue()}_${this.brandLogicToGetSecondaryValue()}`
    psidMap[key] = { psid, expiry: new Date().getTime() + this.psid_ttl }
    setDataInLocalStorage(LOCAL_STORAGE.PSID_MAP, psidMap)
  },
}

export const brand_features = {
  enable_trigger_brand_logic: false,
  enable_onload_brand_logic: false,
  getBrandData() {
    const data = {
      lang: translator.getLanguage(),
      platformName: getPlatform(),
    }
    //=========== BRAND SPECIFIC LOGIC ==========
    const psid = store.getState().chat_details.psid
    const lockedParams = getDataFromLocalStorage(
      LOCAL_STORAGE.APP_PARAMS + psid,
      null
    )
    if (lockedParams) {
      data.lockedParams = lockedParams
      if (lockedParams?.udf3?.identifier)
        localStorage.removeItem(LOCAL_STORAGE.APP_PARAMS + psid)
    }

    const wfid = getQueryParamsValue("chatbot-dashboard-workflow-id", null)
    if (wfid) data.workflow_id = wfid
    //=================== END ===================
    return data
  },
  doBrandLogicOnEndChat(type) {
    if (type === "endChat") {
      if (
        chatbot_setting.gtm.enable &&
        chatbot_setting.gtm.end_chat &&
        window.dataLayer
      )
        window.dataLayer.push({ event: chatbot_setting.gtm.end_chat })
    }
  },
  allowedImageTypeUpload(filename) {
    let bool = false
    //=========== BRAND SPECIFIC LOGIC ==========
    bool = /\.(gif|jpg?g|jpeg|svg|tiff|png)$/i.test(filename)
    //=================== END ===================
    return bool
  },
  showTriggerByBrandLogic() {
    let show_trigger = true
    //=========== BRAND SPECIFIC LOGIC TO FINDOUT TRIGGER  VALUE==========

    //=================== END ===================
    return show_trigger
  },
  allowChatbotByBrandLogic() {
    let isAllowed = true
    //=========== BRAND SPECIFIC LOGIC ON LOAD CHATBOT APP ==========
    //=================== END ===================
    return isAllowed
  },
  doBrandLogicOnChatStateChange(data, dispatch) {
    if (dispatch) {
      //=========== BRAND SPECIFIC LOGIC ON CHAT STATE CHANGE ==========
      // const upload_file = data.chatState === CHAT_STATE.AGENT_HANDLING ? true : chatbot_setting.upload_file
      // setDataInLocalStorage(LOCAL_STORAGE.UPLOAD_FILE + data.psid, upload_file)
      // dispatch(updateChatsState({ upload_file }))
      //=================== END ===================
    }
  },
  doBrandLogicOnLoadChatbotApp() {
    //=========== BRAND SPECIFIC LOGIC ON LOAD CHATBOT APP ==========

    // Uncomment below lines for displaying notification on chatbot load.
    // When Floating Menu is enabled and user clicks on open menu,
    // notification is disabled using is_floating_menu_open.

    // setTimeout(() => {
    //   const is_chat_open = getDataFromLocalStorage(
    //     LOCAL_STORAGE.IS_CHAT_OPEN + chatbot_psids.getPsid(),
    //     false
    //   )
    //   const {is_floating_menu_open} = store.getState().chat_details
    //   if (!is_chat_open && !is_floating_menu_open) {
    //     store.dispatch({
    //       type: actionTypes.UPDATE_CHATS_STATE,
    //       payload: {
    //         unseen_messages: [
    //           {
    //             type: "text",
    //             sender: "chatbot",
    //             inputLock: false,
    //             quickReplies: [],
    //             skipLS: false,
    //             send_variable_to_apiai: false,
    //             sendVariableToLS: false,
    //             variable_name: "",
    //             delay: 0,
    //             payload: {
    //               text: "Need Help? 😊  ",
    //             },
    //           },
    //         ],
    //       },
    //     })
    //   }
    // }, 5000)
    //=================== END ===================
  },
  doSomethingBeforeLoadChatbotThenContinue(continueExecution) {
    //=========== BRAND SPECIFIC LOGIC BEFORE LOAD CHATBOT APP ==========
    continueExecution()
    //=================== END ===================
  },
  doBrandLogicOnClickOut(buttonData) {
    //=========== BRAND SPECIFIC LOGIC ON CLICK BUTTON AND HEADER ==========
    //=================== END ===================
  },
  doBrandLogicOnUserFirstEmit() {
    //=========== BRAND SPECIFIC LOGIC ON USER FIRST EMIT ==========
    //=================== END ===================
  },
  doBrandLogicOnBannerClick() {
    //=========== BRAND SPECIFIC LOGIC ON BANNER CLICK ==========
    //=================== END ===================
  },
  getChatBotDirection() {
    let direction = "ltr" //! it must be either rtl or ltr
    //=========== BRAND SPECIFIC LOGIC TO GET THE DIRECTION OF THE CHATBOT ==========

    //=================== END ===================
    return direction
  },
  doBrandLogicOnBotTrackingSuccess(res) {
    //=========== BRAND SPECIFIC LOGIC TO GET THE DIRECTION OF THE CHATBOT ==========
    console.log("doBrandLogicOnBotTrackingSuccess", res)
    //=================== END ===================
  },
  getAutoEmitMessage() {
    let autoEmitMessage = ""
    //=========== BRAND SPECIFIC LOGIC TO GET AUTO EMIT MESSAGE ==========
    autoEmitMessage = getQueryParamsValue(
      chatbot_setting.auto_emit_message.query_param_key,
      chatbot_setting.auto_emit_message.default_text_message
    )
    //=================== END ===================
    return autoEmitMessage
  },
  doBrandLogicForApp() {
    //=========== BRAND SPECIFIC LOGIC FOR APP ==========

    //=================== END ===================
  }
}

export const adster_settings = {
  banner: true, // to add banner image code
  banner_initial_transtion_delay: 5000, // in ms
  banner_query_params_key: "segment",
  banner_animation_type: "",
  banner_images: {
    default: {
      default:
        "https://dummyimage.com/420x200/000/fff.png&text=default-default",
      ad1: "https://dummyimage.com/420x200/000/fff.png&text=ad1-default",
      ad2: "https://dummyimage.com/420x200/000/fff.png&text=ad2-default",
    },
    "0-0.25": {
      default: "https://dummyimage.com/150x600/000/fff.png&text=default-0-0.25",
      ad1: "https://dummyimage.com/150x600/000/fff.png&text=ad1-0-0.25",
      ad2: "https://dummyimage.com/150x625/000/fff.png&text=ad2-0-0.25",
    },
    "0.26-0.5": {
      default:
        "https://dummyimage.com/320x650/000/fff.png&text=default-0.26-0.5",
      ad1: "https://dummyimage.com/320x650/000/fff.png&text=ad1-0.26-0.5",
      ad2: "https://dummyimage.com/300x600/000/fff.png&text=ad2-0.26-0.5",
    },
    "0.51-0.75": {
      default:
        "https://dummyimage.com/320x480/000/fff.png&text=default-0.51-0.75",
      ad1: "https://dummyimage.com/320x568/000/fff.png&text=ad1-0.51-0.75",
      ad2: "https://dummyimage.com/320x568/000/fff.png&text=ad2-0.51-0.75",
    },
    "0.76-1": {
      default: "https://dummyimage.com/380x500/000/fff.png&text=default-0.76-1",
      ad1: "https://dummyimage.com/380x500/000/fff.png&text=ad1-0.76-1",
      ad2: "https://dummyimage.com/380x500/000/fff.png&text=ad2-0.76-1",
    },
    "1.1-1.25": {
      default:
        "https://dummyimage.com/336x280/383438/fff.png&text=default-1.1-1.25",
      ad1: "https://dummyimage.com/336x280/383438/fff.png&text=ad1-1.1-1.25",
      ad2: "https://dummyimage.com/336x280/383438/fff.png&text=ad2-1.1-1.25",
    },
    "1.26-1.5": {
      default:
        "https://dummyimage.com/300x250/383438/fff.png&text=default-1.26-1.5",
      ad1: "https://dummyimage.com/1024x768/383438/fff.png&text=ad1-1.26-1.5",
      ad2: "https://dummyimage.com/1024x768/383438/fff.png&text=ad2-1.26-1.5",
    },
    "1.51-1.75": {
      default:
        "https://dummyimage.com/480x300/383438/fff.png&text=default-1.51-1.75",
      ad1: "https://dummyimage.com/480x300/383438/fff.png&text=ad1-1.51-1.75",
      ad2: "https://dummyimage.com/480x300/383438/fff.png&text=ad2-1.51-1.75",
    },
    "1.76-2": {
      default: "https://dummyimage.com/568x320/000/fff.png&text=default-1.75-2",
      ad1: "https://dummyimage.com/568x320/000/fff.png&text=ad1-1.75-2",
      ad2: "https://dummyimage.com/568x320/000/fff.png&text=ad1-1.75-2",
    },
  },
  getBannerByRange(range, key) {
    if (this.banner_images[range][key]) return this.banner_images[range][key]
    return this.banner_images[range].default
  },
  getBannerByAspectRatio(aspectRatio, key) {
    if (aspectRatio <= 0.25) return this.getBannerByRange("0-0.25", key)
    else if (aspectRatio <= 0.5) return this.getBannerByRange("0.26-0.5", key)
    else if (aspectRatio <= 0.75) return this.getBannerByRange("0.51-0.75", key)
    else if (aspectRatio <= 1) return this.getBannerByRange("0.76-1", key)
    else if (aspectRatio <= 1.25) return this.getBannerByRange("1.1-1.25", key)
    else if (aspectRatio <= 1.5) return this.getBannerByRange("1.26-1.5", key)
    else if (aspectRatio <= 1.75) return this.getBannerByRange("1.51-1.75", key)
    else if (aspectRatio <= 2) return this.getBannerByRange("1.76-2", key)
    else return this.getBannerByRange("default", key)
  },
}
