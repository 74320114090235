import {
  MESSAGE_SENDER,
  MESSAGE_TYPES,
  // BUTTON_SUB_TYPES,
  BUTTON_TYPES,
} from "./constants"

export const getDefaultMessagesByKey = (key = "web") => {
  const defaultMessgaes = {
    web: [
      {
        cmid: "message1",
        type: MESSAGE_TYPES.TEXT,
        sender: MESSAGE_SENDER.CHATBOT,
        inputLock: false,
        quickReplies: [],
        skipLS: false,
        send_variable_to_apiai: false,
        sendVariableToLS: false,
        variable_name: '',
        delay: 0,
        payload: {
          text: "Hi, I'm Vic, your personal Vi assistant."
        },
        timestamp: new Date()
      },
      {
        cmid: "msg2",
        sender: MESSAGE_SENDER.CHATBOT,
        type: MESSAGE_TYPES.TEXT_WITH_BUTTONS,
        inputLock: false,
        inputLockMessage: '',
        quickReplies: [],
        skipLS: true,
        send_variable_to_apiai: false,
        sendVariableToLS: false,
        variable_name: 'subscription',
        delay: 0,
        payload: {
          title: "",
          subtitle: "What would you like to explore today?",
          buttons: [
            {
              type: BUTTON_TYPES.DEFAULT,
              text: "Prepaid",
              url: ""
            },
            {
              type: BUTTON_TYPES.DEFAULT,
              text: "Postpaid",
              url: ""
            },
            {
              type: BUTTON_TYPES.DEFAULT,
              text: "Join Vi Family",
              url: ""
            },
            {
              type: BUTTON_TYPES.DEFAULT,
              text: "हिंदी भाषा में दिखाएं",
              url: ""
            }
          ]
        },
        timestamp: new Date(),
      }
    ],
    android: [
      {
        cmid: "message1",
        type: MESSAGE_TYPES.TEXT,
        sender: MESSAGE_SENDER.CHATBOT,
        inputLock: false,
        quickReplies: [],
        skipLS: false,
        send_variable_to_apiai: false,
        sendVariableToLS: false,
        variable_name: '',
        delay: 0,
        payload: {
          text: "Hi, I'm Vic, your personal Vi assistant."
        },
        timestamp: new Date()
      },
      {
        cmid: "msg2",
        sender: MESSAGE_SENDER.CHATBOT,
        type: MESSAGE_TYPES.TEXT_WITH_BUTTONS,
        inputLock: false,
        inputLockMessage: '',
        quickReplies: [],
        skipLS: true,
        send_variable_to_apiai: false,
        sendVariableToLS: false,
        variable_name: 'subscription',
        delay: 0,
        payload: {
          title: "",
          subtitle: "What would you like to explore today?",
          buttons: [
            {
              type: BUTTON_TYPES.DEFAULT,
              text: "Prepaid",
              url: ""
            },
            {
              type: BUTTON_TYPES.DEFAULT,
              text: "Postpaid",
              url: ""
            },
            {
              type: BUTTON_TYPES.DEFAULT,
              text: "Join Vi Family",
              url: ""
            },
            {
              type: BUTTON_TYPES.DEFAULT,
              text: "हिंदी भाषा में दिखाएं",
              url: ""
            }
          ]
        },
        timestamp: new Date(),
      }
    ],
  }
  return defaultMessgaes[key] || []
}
