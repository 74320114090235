import message from "antd/lib/message"
import { chatbot_setting } from "./brandSetup"

import {
  LOCAL_STORAGE,
  PLATFORM,
  MESSAGE_SENDER,
  LANGUAGES,
  MESSAGE_TYPES,
  CHATBOT_TYPE,
} from "./constants"

const s4 = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)
}

export const guid = () => {
  return s4() + s4() + s4() + s4()
}

export const uniqueId = () => {
  let time = new Date().getTime()
  return `${time}_${guid()}`
}

export const log = (...arg) => {
  if (process.env.NODE_ENV === "development") console.log(...arg)
}

export const isJson = (str) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const isEmptyObject = (obj) => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) return false
  }
  return true
}

export const getPlatform = () => {
  let platform = PLATFORM.WEBSITE
  if (chatbot_setting.chatbot_type === CHATBOT_TYPE.ADSTER)
    return CHATBOT_TYPE.ADSTER
  if (window.ori_platform && window.ori_platform !== undefined) {
    platform = window.ori_platform.toLowerCase()
  }
  //=========== BRAND SPECIFIC LOGIC FOR GETTING PLATFORM ==========
  
  //=================== END ===================
  return platform
}

export const showMessage = (type, msg) => {
  const node = document.getElementById("chatbotContentContainer")
  if (node) {
    message.config({
      duration: 2,
      maxCount: 1,
      top: 75,
      getContainer: () => node,
    })
    message[type](msg)
  }
}

export const getRandomIntegerInclusive = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1) + min) //Both max and min are inclusive
}

export const fetchWithTimeout = (url, options, timeout = 5000) => {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error("timeout")), timeout)
    ),
  ])
}

export const checkMultipleExtension = (filename) => {
  let numberOfExtensions = filename.split(".")
  return numberOfExtensions.length > 2 ? false : true
}

export const isImageExist = (url) => {
  return new Promise((resolve) => {
    if (url) {
      const img = new Image()
      img.onload = () => resolve(true)
      img.onerror = () => resolve(false)
      img.src = url
    } else resolve(false)
  })
}

export const getImageMetaData = (src, callback) => {
  const image = new Image()
  image.src = src
  image.onload = function () {
    if (callback)
      callback({
        width: this.width,
        height: this.height,
      })
  }
}

export const fileToBase64 = (file) => {
  return new Promise((resolve) => {
    var reader = new FileReader()
    reader.onload = (event) => {
      resolve(event.target.result)
    }
    reader.readAsDataURL(file)
  })
}

export const getCookie = (name) => {
  var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)")
  return v ? v[2] : null
}

export const isAndroid = () => {
  let platform = getPlatform()
  return platform === PLATFORM.ANDROID
}

export const isIOS = () => {
  let platform = getPlatform()
  return platform === PLATFORM.IOS
}

export const getDataFromLocalStorage = (key, undefined_return_value) => {
  let data = localStorage.getItem(key)
  if (data === undefined || data === null) return undefined_return_value
  if (isJson(data)) data = JSON.parse(data)
  return data
}

export const setDataInLocalStorage = (key, data) => {
  try {
    const json_data = JSON.stringify(data)
    localStorage.setItem(key, json_data)
  } catch (e) {
    if (
      e instanceof DOMException &&
      (e.code === 22 ||
        e.code === 1014 ||
        e.name === "QuotaExceededError" ||
        e.name === "NS_ERROR_DOM_QUOTA_REACHED")
    ) {
      localStorage.clear()
      console.log("localStorage error:-", e.code, e.name)
    }
  }
}

export const clearAllDataFromLocalStorage = (psid) => {
  if (psid) {
    localStorage.removeItem(LOCAL_STORAGE.MESSAGES + psid)
    localStorage.removeItem(LOCAL_STORAGE.LAST_EMIT + psid)
    localStorage.removeItem(LOCAL_STORAGE.END_CHAT + psid)
    localStorage.removeItem(LOCAL_STORAGE.IS_CHAT_OPEN + psid)
    localStorage.removeItem(LOCAL_STORAGE.NOTIFICATION_COUNT + psid)
    localStorage.removeItem(LOCAL_STORAGE.UNSEEN_MESSAGES + psid)
    localStorage.removeItem(LOCAL_STORAGE.APP_PARAMS + psid)
    localStorage.removeItem(LOCAL_STORAGE.UPLOAD_FILE + psid)
    localStorage.removeItem(LOCAL_STORAGE.CHAT_STATE + psid)
  }
}

export const getPreviousMessageData = (messages, key, defaultData) => {
  let data = defaultData
  const senders = [
    MESSAGE_SENDER.ADMIN,
    MESSAGE_SENDER.CHATBOT,
    MESSAGE_SENDER.SYSTEM,
  ]
  if (
    messages &&
    messages.length > 0 &&
    senders.includes(messages[messages.length - 1].sender) &&
    messages[messages.length - 1][key] !== undefined
  )
    data = messages[messages.length - 1][key]
  return data
}

export const getQueryParamsValue = (key, default_value) => {
  let value = default_value
  const query_params = new URLSearchParams(window.location.search)
  if (query_params.has(key)) value = query_params.get(key)
  return value
}

export const formatDate = (value, options, lang) => {
  let locale = "en-In"
  if (lang && lang === LANGUAGES.ARABIC) locale = "ar"
  let date = new Date(value)
  return date.toLocaleDateString(locale, options)
}

export const formatTime = (value, options, lang) => {
  let locale = "en-In"
  if (lang && lang === LANGUAGES.ARABIC) locale = "ar"
  let date = new Date(value)
  return date.toLocaleTimeString(locale, options)
}

export const scrollToBottom = (node) => {
  if (node) {
    node.scrollTop = node.scrollHeight
  }
}

export const checkDevice = {
  screen_data: function () {
    return {
      screen_width: window.innerWidth,
      screen_height: window.innerHeight,
      screen_orientation: this.screen_orientation(),
      screen_type: this.screen_type(),
    }
  },
  screen_orientation: function () {
    if (window.matchMedia("(orientation:landscape)").matches) {
      return "landscape"
    } else {
      return "portrait"
    }
  },
  screen_type: function () {
    if (window.innerWidth <= 480) {
      return "xs"
    } else if (window.innerWidth <= 768) {
      return "sm"
    } else if (window.innerWidth <= 992) {
      return "md"
    } else if (window.innerWidth <= 1200) {
      return "lg"
    } else if (window.innerWidth <= 1600) {
      return "hd"
    } else if (window.innerWidth <= 2560) {
      return "fhd"
    } else {
      return "uhd"
    }
  },
  deviceStatus: function () {
    return {
      ...this.screen_data(),
    }
  },
}

export const isEmail = (value) => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (value.match(pattern)) return true
  return false
}

export const openUrlInNewTab = (url, target = "_blank") => {
  if (url && url.trim().length > 0) {
    let win = window.open(url, target)
    win.focus()
  }
}

export const encrypt = ((salt) => {
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0))
  const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2)
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code)

  return (text) =>
    text.split("").map(textToChars).map(applySaltToChar).map(byteHex).join("")
})(process.env.REACT_APP_CIPHER_SECRET_KEY)

export const isDeviceIOS = () => {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  )
}

export const throttled = (func, delay) => {
  let timerId
  return (...args) => {
    if (!timerId) {
      func(...args)
      timerId = setTimeout(() => {
        timerId = null
      }, delay)
    }
  }
}

export const isAppleDevice = () => {
  return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
}

export const debounced = (func, delay) => {
  let timerId
  return (...args) => {
    if (timerId) clearTimeout(timerId)
    timerId = setTimeout(() => {
      func(...args)
      timerId = null
    }, delay)
  }
}

export const getMessageIdentifier = (message) => {
  if (message && message.type && message.payload)
    switch (message.type) {
      case MESSAGE_TYPES.TEXT:
        return Array.isArray(message.payload.text)
          ? message.payload.text[0]
          : message.payload.text
      case MESSAGE_TYPES.TEXT_WITH_BUTTONS:
      case MESSAGE_TYPES.IMAGE_WITH_BUTTONS:
      case MESSAGE_TYPES.VIDEO:
      case MESSAGE_TYPES.SEAT:
      case MESSAGE_TYPES.UPLOAD_FILE:
      case MESSAGE_TYPES.CAROUSEL:
      case MESSAGE_TYPES.FORM: {
        let identifier = ""
        if (message.payload.title) {
          if (message.payload.subtitle)
            identifier = message.payload.title.concat(message.payload.subtitle)
          else identifier = message.payload.title
        } else if (message.payload.subtitle)
          identifier = message.payload.subtitle
        return identifier
      }
      case MESSAGE_TYPES.UPLOADED_DOCUMENT:
        return message?.payload?.fileName || "Image/File"
      default:
        return ""
    }
  return ""
}

export const setupHistoryListener = () => {
  let oldPushState = window.history.pushState
  window.history.pushState = (...args) => {
    let originalReturnValue = oldPushState.apply(window.history, args)
    window.dispatchEvent(new Event('locationchange'))
    return originalReturnValue
  }

  let oldReplaceState = window.history.replaceState
  window.history.replaceState = (...args) => {
    let originalReturnValue = oldReplaceState.apply(window.history, args)
    window.dispatchEvent(new Event('locationchange'))
    return originalReturnValue
  }

  window.addEventListener('popstate', () => {
    window.dispatchEvent(new Event('locationchange'))
  })
}

/**
 * Used to blur click event after the button is clicked
 * @param {*} e
 */
export const blurButtonAfterClick = (e) => {
  let target = e.target
  if (target && target.tagName !== 'BUTTON') target = target.parentElement
  if (target && target.blur) target.blur()
}
